import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ANDROID_APP_URL, IOS_APP_URL } from '../../../shared/constants.platform';
import { globalState } from '../../../shared/foreground/models';
import { setIntegrationConnected } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/integrations';
import { queueJob } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/jobs';
import {
  bumpHelpDocDatesToNow,
  updateOnboardingStep,
} from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/onboarding';
import { setPathNameToRedirectAfterOnboarding } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { createToast } from '../../../shared/foreground/toasts.platform';
import { JobType, OnboardingStep, OnboardingStepOrder } from '../../../shared/types';
import {
  BrowserType,
  getCurrentBrowser,
  isAndroidOrIOS,
  isIOS,
} from '../../../shared/utils/environment';
import getServerBaseUrl from '../../../shared/utils/getServerBaseUrl.platform';
import { useShouldForceMobileApp } from '../hooks/useShouldForceMobileApp';
import openPopupWindow from '../utils/openPopupWindow';
import { openURL } from '../utils/openURL';
import useLocation from '../utils/useLocation';
import Button from './Button';
import AndroidIcon from './icons/AndroidIcon';
import AppleIcon from './icons/AppleIcon';
import LogoIcon from './icons/LogoIcon';
import StrokeCheckboxOff24 from './icons/StrokeCheckboxOff24';
import StrokeCheckboxOn24 from './icons/StrokeCheckboxOn24';
import styles from './OnboardingPage.module.css';
import StandardPage from './StandardPage';
import Tooltip from './Tooltip';

const ConnectReadItLater = () => {
  const instapaperConnected = globalState((s) => s.persistent.integrations?.instapaper?.connected);
  const pocketConnected = globalState((s) => s.persistent.integrations?.pocket?.connected);

  return (
    <div className={styles.connectReadItLater}>
      <h1 className={styles.onboardingHeader}>Import your existing content</h1>
      <p className={styles.onboardingSubtext}>
        Immediately populate your Reader account with all the articles you&apos;ve previously saved to
        Instapaper and Pocket.
      </p>
      <div className={styles.connectionRows}>
        <hr />
        <div className={styles.connectionRow}>
          <img
            className={styles.sourceLogo}
            alt="instapaper logo"
            src="/onboarding/instapaper_logo.png"
          />
          <div className={styles.sourceInfo}>
            <div className={styles.sourceTitle}>Instapaper</div>
            <div className={styles.sourceDescription}>
              {instapaperConnected ? 'Your Instapaper is connected' : 'No account connected'}
            </div>
          </div>
          <div className={styles.sourceConnect}>
            {!instapaperConnected && (
              <Button
                variant="default"
                onClick={(e: Event) => {
                  e.preventDefault();
                  const popupWindow = openPopupWindow({
                    url: `${getServerBaseUrl()}/instapaper_login`,
                    title: 'Connect Reader to Instapaper',
                    w: 800,
                    h: 600,
                  });
                  window.addEventListener('message', (event) => {
                    if (event?.data.accountConnected) {
                      setIntegrationConnected('instapaper');
                    }
                  });
                  setInterval(() => {
                    popupWindow?.postMessage('checkInstapaperStatus', '*');
                  }, 200);
                }}
                className={styles.connectButton}
              >
                Connect
              </Button>
            )}
          </div>
        </div>
        <hr />
        <div className={styles.connectionRow}>
          <img className={styles.sourceLogo} alt="pocket logo" src="/onboarding/pocket_logo.png" />
          <div className={styles.sourceInfo}>
            <div className={styles.sourceTitle}>Pocket</div>
            <div className={styles.sourceDescription}>
              {pocketConnected ? 'Your Pocket is connected' : 'No account connected'}
            </div>
          </div>
          <div className={styles.sourceConnect}>
            {!pocketConnected && (
              <Button
                variant="default"
                onClick={(e: Event) => {
                  e.preventDefault();
                  const popupWindow = openPopupWindow({
                    url: `${getServerBaseUrl()}/pocket_auth_start/?isReader=1`,
                    title: 'Connect Reader to Pocket',
                    w: 800,
                    h: 600,
                  });
                  window.addEventListener('message', (event) => {
                    if (event?.data.accountConnected) {
                      setIntegrationConnected('pocket');
                    }
                  });
                  setInterval(() => {
                    popupWindow?.postMessage('checkPocketStatus', '*');
                  }, 200);
                }}
                className={styles.connectButton}
              >
                Connect
              </Button>
            )}
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

const InstallExtension = ({ setClickedOnLink }: { setClickedOnLink: (link: string) => void }) => {
  const currentBrowser = getCurrentBrowser();

  return (
    <div className={styles.installExtension}>
      <h1 className={styles.onboardingHeader}>Install the browser extension</h1>
      <p className={styles.onboardingSubtext}>
        The Reader browser extension saves articles to your Reader account and enables you to optionally
        highlight the open web.
      </p>
      <div className={styles.extensionLinks}>
        <a
          onClick={() =>
            setClickedOnLink(
              'https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi',
            )
          }
          className={`${styles.onboardingExtensionLink} ${
            currentBrowser === BrowserType.Chrome ? '' : styles.disabled
          }`}
          href="https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi"
          target="popup"
          rel="noreferrer"
        >
          <img
            className={styles.extensionLogo}
            alt="chrome extension logo"
            src="/onboarding/chrome.svg"
          />
          <span className={styles.extensionInstallButton}>Install</span>
        </a>
        <a
          onClick={() =>
            setClickedOnLink('https://addons.mozilla.org/en-US/firefox/addon/readwise-highlighter')
          }
          className={`${styles.onboardingExtensionLink} ${
            currentBrowser === BrowserType.Firefox ? '' : styles.disabled
          }`}
          href="https://addons.mozilla.org/en-US/firefox/addon/readwise-highlighter"
          target="popup"
          rel="noreferrer"
        >
          <img
            className={styles.extensionLogo}
            alt="firefox extension logo"
            src="/onboarding/firefox.svg"
          />
          <span className={styles.extensionInstallButton}>Install</span>
        </a>
        <a
          onClick={() => setClickedOnLink('https://apps.apple.com/app/save-to-reader/id1640236961')}
          className={`${styles.onboardingExtensionLink} ${
            currentBrowser === BrowserType.Safari ? '' : styles.disabled
          }`}
          href="https://apps.apple.com/app/save-to-reader/id1640236961"
          target="popup"
          rel="noreferrer"
        >
          <img
            className={styles.extensionLogo}
            alt="safari extension logo"
            src="/onboarding/safari.svg"
          />
          <span className={styles.extensionInstallButton}>Install</span>
        </a>
        <a
          onClick={() =>
            setClickedOnLink(
              'https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi',
            )
          }
          className={`${styles.onboardingExtensionLink} ${
            currentBrowser === BrowserType.Brave ? '' : styles.disabled
          }`}
          href="https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi"
          target="popup"
          rel="noreferrer"
        >
          <img className={styles.extensionLogo} alt="brave extension logo" src="/onboarding/brave.svg" />
          <span className={styles.extensionInstallButton}>Install</span>
        </a>
        <a
          onClick={() =>
            setClickedOnLink(
              'https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi',
            )
          }
          className={`${styles.onboardingExtensionLink} ${
            currentBrowser === BrowserType.Edge ? '' : styles.disabled
          }`}
          href="https://chrome.google.com/webstore/detail/readwise-highlighter/jjhefcfhmnkfeepcpnilbbkaadhngkbi"
          target="popup"
          rel="noreferrer"
        >
          <img className={styles.extensionLogo} alt="edge extension logo" src="/onboarding/edge.svg" />
          <span className={styles.extensionInstallButton}>Install</span>
        </a>
      </div>
    </div>
  );
};

const InstallApp = ({
  mobileAppSelection,
  setMobileAppSelection,
}: {
  mobileAppSelection: string;
  setMobileAppSelection: (device: string) => void;
}) => {
  return (
    <div className={styles.installApp}>
      <h1 className={styles.onboardingHeader}>Get the mobile app</h1>
      <p className={styles.onboardingSubtext}>
        We’ll email you a link to install the app on your Android or iOS device. Choose your platform and
        hit continue.
      </p>
      <div className={styles.mobileAppSelectionContainer}>
        <button
          className={`${styles.mobileAppButton} ${
            mobileAppSelection === 'android' && styles.mobileAppButtonSelected
          }`}
          onClick={() => setMobileAppSelection('android')}
          type="button"
        >
          {mobileAppSelection === 'android' ? <StrokeCheckboxOn24 /> : <StrokeCheckboxOff24 />}
          <div>
            <h3>Android</h3>
            <span>Email a link to the Reader app in the Google Play Store</span>
          </div>
        </button>
        <button
          className={`${styles.mobileAppButton} ${
            mobileAppSelection === 'ios' && styles.mobileAppButtonSelected
          }`}
          onClick={() => setMobileAppSelection('ios')}
          type="button"
        >
          {mobileAppSelection === 'ios' ? <StrokeCheckboxOn24 /> : <StrokeCheckboxOff24 />}
          <div>
            <h3>iOS</h3>
            <span>Email a link to the Reader app in the Apple App Store</span>
          </div>
        </button>
      </div>
    </div>
  );
};

// TODO: These will be handy in the future
// const BrowserHistory = () => {
//   return <div className={styles.browserHistory}>
//     <h1 className={styles.onboardingHeader}>Load up everything you've been reading</h1>
//     <p className={styles.onboardingSubtext}>Import articles from your browsing history. No browsing history will leave your device: only the articles you select here.</p>
//   </div>;
// };

const Ready = () => {
  return (
    <div className={styles.installApp}>
      <h1 className={styles.onboardingHeader}>You&apos;re ready to read</h1>
      <p className={styles.onboardingSubtext}>
        Reader brings all your content into one place including blog posts, email newsletters, RSS feeds,
        Twitter threads, EPUBs, PDFs, and more. Explore the web app to learn how.
      </p>
    </div>
  );
};

const InternalOnboardingPage = () => {
  const furthestOnboardingStep = globalState(
    useCallback((state) => {
      return state.persistent.onboardingStep;
    }, []),
  );

  const pathNameToRedirectAfterOnboarding = globalState(
    (state) => state.pathNameToRedirectAfterOnboarding,
  );

  const [mobileAppSelection, setMobileAppSelection] = useState('ios'); // ios or android
  const [appSelectionEmailQueued, setAppSelectionEmailQueued] = useState(false);

  useEffect(() => {
    setAppSelectionEmailQueued(false);
  }, [mobileAppSelection]);

  const [clickedOnExtension, setClickedOnExtension] = useState(false);

  const onExtensionLinkClicked = useCallback((extensionLink: string) => {
    setClickedOnExtension(true);
    openURL(extensionLink, 'popup', 'width=1135,height=600');
    return false;
  }, []);

  useEffect(() => {
    if (furthestOnboardingStep) {
      history.push(furthestOnboardingStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [furthestOnboardingStep]);

  const { pathname } = useLocation() as { pathname: OnboardingStep };
  const [isHidden, setIsHidden] = useState(false);
  const history = useHistory();
  let uniqueSection;
  let rightImageClass: string = styles.readLater;
  let continueButtonText = 'Continue →';

  useEffect(() => {
    const sendAppEmail = async () => {
      await queueJob({
        jobType: JobType.GenerateEmailForAppInstall,
        jobArguments: { platform: mobileAppSelection },
        options: { userInteraction: 'click' },
      });
      createToast({ content: `Installation email sent`, category: 'success' });
    };

    updateOnboardingStep(pathname);
    if (pathname === OnboardingStep.Ready && !appSelectionEmailQueued) {
      setAppSelectionEmailQueued(true);
      sendAppEmail();

      // Quick little hack to make sure that a user's help documents are at the top of their inbox/archive
      bumpHelpDocDatesToNow();
    }

    if (pathname === OnboardingStep.Welcome) {
      queueJob({
        jobType: JobType.SetUpNewSpace,
        jobArguments: {},
        options: { userInteraction: null },
      });
    }
  }, [appSelectionEmailQueued, mobileAppSelection, pathname]);

  const nextSectionUrl = OnboardingStepOrder[OnboardingStepOrder.indexOf(pathname) + 1];
  const prevSectionUrl = OnboardingStepOrder[OnboardingStepOrder.indexOf(pathname) - 1];

  if (pathname === OnboardingStep.Welcome) {
    uniqueSection = <ConnectReadItLater />;
    rightImageClass = styles.readLater;
  } else if (pathname === OnboardingStep.Extension) {
    uniqueSection = <InstallExtension setClickedOnLink={onExtensionLinkClicked} />;
    rightImageClass = styles.extension;
  } else if (pathname === OnboardingStep.MobileApp) {
    uniqueSection = (
      <InstallApp
        mobileAppSelection={mobileAppSelection}
        setMobileAppSelection={setMobileAppSelection}
      />
    );
    rightImageClass = styles.mobile;
  } else if (pathname === OnboardingStep.Ready) {
    uniqueSection = <Ready />;
    rightImageClass = styles.ready;
    continueButtonText = 'Start reading';
  }

  const continueButtonDisabled = pathname === OnboardingStep.Extension && !clickedOnExtension;
  return (
    <StandardPage isSidebarHidden>
      <main id="onboarding-page" className={styles.onboarding}>
        <div className={[styles.onboardingInfo, isHidden ? styles.isHidden : ''].join(' ')}>
          <div className={styles.leftInfoBox}>
            <div className={styles.welcomeMessage}>
              <LogoIcon className={styles.readerIcon} />
              <span className={styles.welcomeText}>Welcome to Readwise Reader</span>
            </div>
            {uniqueSection}
            <div className={styles.continueSection}>
              {prevSectionUrl && (
                <Link to={prevSectionUrl} className={styles.previousStep}>
                  ← Previous step
                </Link>
              )}
              <Tooltip
                content="Install the extension to continue"
                placement="top"
                disabled={!continueButtonDisabled}
              >
                <Button
                  variant="blue"
                  onClick={() => {
                    setIsHidden(true);
                    setTimeout(() => {
                      if (pathname === OnboardingStep.Ready && pathNameToRedirectAfterOnboarding) {
                        history.push(pathNameToRedirectAfterOnboarding);
                        setPathNameToRedirectAfterOnboarding(null);
                      } else {
                        history.push(nextSectionUrl);
                      }
                      setIsHidden(false);
                    }, 250);
                  }}
                  className={`${styles.continueButton} ${
                    continueButtonDisabled ? styles.disabledButton : ''
                  }`}
                >
                  {continueButtonText}
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className={[styles.rightInfoBox, rightImageClass].join(' ')} />
        </div>
        <div className={styles.progressPillContainer}>
          {OnboardingStepOrder.slice(0, OnboardingStepOrder.length - 1).map((step, i) => {
            return (
              <div
                key={`progress-${step}`}
                className={`${styles.progressPill} ${step === pathname ? styles.active : ''}`}
              />
            );
          })}
        </div>
      </main>
    </StandardPage>
  );
};

export default function OnboardingPage() {
  const shouldForceMobileApp = useShouldForceMobileApp();

  const persistentStateLoaded = globalState(
    useCallback((state) => Boolean(state.clientStateLoaded), []),
  );
  const [mobileAppCTADismissed] = useState(false);
  if (!persistentStateLoaded) {
    return null;
  }

  if (window.innerWidth < 600 && isAndroidOrIOS && (shouldForceMobileApp || !mobileAppCTADismissed)) {
    return (
      <div className={styles.mobileDevicePage}>
        <div className={styles.mobileDeviceInfo}>
          <img
            className={styles.mobileDeviceImage}
            alt="reader mobile app logo"
            src="/onboarding/readwise-dark.png"
          />
          <h1>To get started on mobile install the app.</h1>
        </div>
        <a className={styles.mobileDeviceLink} href={isIOS ? IOS_APP_URL : ANDROID_APP_URL}>
          {isIOS ? <AppleIcon className={styles.icon} /> : <AndroidIcon className={styles.icon} />}
          {isIOS ? 'Download on the App Store' : 'Download on the Play Store'}
        </a>
      </div>
    );
  }

  return <InternalOnboardingPage />;
}
